var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Information de l'agence")])]},proxy:true}])},[_c('div',{staticClass:"text-right mb-4"},[_c('a-button',{staticClass:"mx-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Retour")])],1),_c('a-col',{attrs:{"span":24}},[_c('a-card',{staticClass:"card-billing-info",attrs:{"bordered":false}},[_c('div',{staticClass:"col-info"},[_c('a-descriptions',{attrs:{"title":'Date de création: ' +
                  new Date(_vm.agence.created_at).toLocaleString(),"column":3}},[_c('a-descriptions-item',{attrs:{"label":"Nom"}},[_vm._v(" "+_vm._s(_vm.agence.nom_agence)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Ville"}},[_vm._v(" "+_vm._s(_vm.ville)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Quartier"}},[_vm._v(" "+_vm._s(_vm.quartier)+" ")])],1)],1)])],1)],1)],1),_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: '20px', paddingBottom: '16px' }}},[_c('a-form',{staticClass:"affect-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.formCarnetAffect,"hideRequiredMark":true},on:{"submit":_vm.agentCarnetSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24,"md":14}},[_c('a-form-item',{attrs:{"colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'nbrDay',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Nombre de jour vide!',
                        } ],
                    } ]),expression:"[\n                    'nbrDay',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Nombre de jour vide!',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"type":"number","placeholder":"Nombre de jour"}})],1)],1),_c('a-col',{attrs:{"span":24,"md":4}},[_c('div',{staticClass:"text-right mt-1"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Chercher client ")])],1)])],1)],1)],1)],1),_c('a-col',{attrs:{"span":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Liste des clients inactifs")])]},proxy:true}])},[_c('a-table',{attrs:{"columns":_vm.columns_client,"data-source":_vm.data_client,"pagination":true},scopedSlots:_vm._u([{key:"operation",fn:function(text, record){return [_c('router-link',{attrs:{"to":{ name: 'Client_detail', params: { id: record.key } }}},[_c('a-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("Détail")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }